













































































































import { Component, Vue, Watch } from 'vue-property-decorator'
import VsConfirm from '@/components/VsConfirm/Index.vue'
import VsBlockedFunctionalityModal from '@/components/VsBlockedFunctionalityModal/Index.vue'
import VsLoader from '@/components/VsLoader/Index.vue'
import VsContainer from '@/components/VsContainer/Index.vue'
import VsSectionHeader from '@/components/VsSectionHeader/Index.vue'
import VsWhatsappTemplateCard from '../components/VsWhatsappTemplateCard/Index.vue'
import VsCreateWaTemplateModal from '../components/VsCreateWaTemplateModal/Index.vue'
import VsWhatsappIntegration from '@/modules/whatsapp/components/VsWhatsappIntegration/Index.vue'
import { deleteWaTemplates, getIntegration, getWaTemplates } from '@/api/consoleApi/contents/whatsapp'
import VsPreviewModal from '@/modules/campaigns/components/VsPreviewModal/Index.vue'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'WhatsappTemplates',
    components: {
        VsConfirm,
        VsBlockedFunctionalityModal,
        VsLoader,
        VsContainer,
        VsSectionHeader,
        VsWhatsappTemplateCard,
        VsPreviewModal,
        VsCreateWaTemplateModal,
        VsWhatsappIntegration,
    },
})
export default class extends Vue {
    private loading = false
    private templates: any[] = []
    private integration: any = null
    private phone = ''
    private total = 0
    private filters = {
        search: '',
        status: '',
    }

    private pagination: any = {
        page: 1,
        itemsPerPage: 10,
        orderBy: null,
    }

    $refs!: any

    get tamplateStatuses () {
        return [
            {
                value: 'approved',
                label: 'Approvato',
            },
            {
                value: 'pending',
                label: 'In approvazione',
            },
            {
                value: 'rejected',
                label: 'Rifiutato',
            },
        ]
    }

    get templateOrderBy () {
        return [
            {
                label: 'Più recenti',
                value: 'id,desc',
            },
            {
                label: 'Meno recenti',
                value: 'id,asc',
            },
        ]
    }

    async beforeMount () {
        await this.getIntegration()
        this.getWhatsappTemplates()
    }

    async getIntegration () {
        this.loading = true
        try {
            const resp = await getIntegration()
            this.integration = resp.data.data
        } catch (e) {
            console.log(e)
            this.integration = null
        }
        this.loading = false
    }

    @Watch('filters', { deep: true, immediate: false })
    @Watch('pagination', { deep: true, immediate: false })
    async getWhatsappTemplates () {
        this.loading = true
        try {
            const resp = await getWaTemplates(this.buildParams())
            this.templates = resp.data.data
            this.total = resp.data.meta.pagination.total
        } catch (e) {
            console.log(e)
            this.templates = []
        }
        this.loading = false
    }

    private buildParams () {
        const params: any = {
            orderBy: 'id',
            sortedBy: 'desc',
            searchJoin: 'and',
            page: this.pagination.page,
            limit: this.pagination.itemsPerPage,
        }

        const searches = []
        const searchesFields = []

        if (this.filters.status) {
            searches.push(`status:${this.filters.status}`)
            searchesFields.push('status')
        }

        if (this.filters.search && this.filters.search.trim() !== '') {
            searches.push(`name:${this.filters.search}`)
            searchesFields.push('name')
        }

        if (this.pagination.orderBy) {
            const ord = this.pagination.orderBy.split(',')
            params.orderBy = ord[0]
            params.sortedBy = ord[1]
        }

        if (searches.length > 0) {
            params.search = searches.join(';')
            params.searchFields = searchesFields.join(';')
        }
        return params
    }

    async deleteTemplate (id: string) {
        try {
            await this.$refs.confirmDeleteTemplate.openConfirm()
        } catch (e) {
            return
        }
        this.loading = true
        try {
            await deleteWaTemplates(id)
            this.getWhatsappTemplates()
            this.$root.$vsToast({
                heading: 'Template eliminato con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante l\'eliminazione del template',
                aspect: VsToastAspectEnum.alert,
                timeout: 3000,
            })
            this.loading = false
        }
    }

    private openPreviewModal (template: any) {
        this.$refs.previewModal.openModalNew({
            ...template,
            whatsapp_sender: this.integration?.sourceNumber || '',
        }, 'whatsapp')
    }

    private openCreateTemplateModal () {
        this.$refs.createTemplateModal.openModal()
    }
}
