import { serviceConsoleApi } from '@/utils/request'
import { CancelToken } from 'axios'

export const createCustomField = (data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.post(
        `/custom_fields/`,
        data,
        {
            cancelToken,
        },
    )

export const updateCustomField = (customFieldId: string | number, data: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.put(
        `/custom_fields/${customFieldId}`,
        data,
        {
            cancelToken,
        },
    )

export const getCustomFields = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/custom_fields`,
        {
            cancelToken,
            params,
        },
    )

export const getCustomField = (customFieldId: string | number, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/custom_fields/${customFieldId}`,
        {
            cancelToken,
        },
    )

export const getCustomFieldsPlaceholders = (params?: any, cancelToken?: CancelToken) =>
    serviceConsoleApi.get(
        `/custom_fields/placeholders`,
        {
            cancelToken,
            params,
        },
    )

export const deleteCustomField = (customFieldId: string | number, cancelToken?: CancelToken) =>
    serviceConsoleApi.delete(
        `/custom_fields/${customFieldId}`,
        {
            cancelToken,
        },
    )
