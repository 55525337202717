






















































































































import { Component, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { createWaTemplate } from '@/api/consoleApi/contents/whatsapp'
import { Picker } from 'emoji-mart-vue-2'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'
import { getCustomFieldsPlaceholders } from '@/api/consoleApi/customFields'

@Component({
    name: 'VsCreateWaTemplateModal',
    components: {
        VsListingCard,
        VsDropdownButton,
        Picker,
    },
})
export default class extends Vue {
    open = false
    loading = false
    name = ''
    body = ''
    customFields = []
    cursorPosition: undefined | number = undefined
    $refs!: any

    openModal () {
        this.loading = false
        this.cursorPosition = undefined
        this.name = ''
        this.body = ''
        this.customFields = []
        this.getCustomFields()
        this.open = true
    }

    closeModal () {
        this.open = false
    }

    get bodyPlaceholders () {
        const regex = /\{\{\s*(.*?)\s*\}\}/g
        return this.body.match(regex) || []
    }

    private detectBodyInputCursorPosition () {
        this.cursorPosition = this.$refs?.bodyInput?.$el.childNodes[1].selectionStart || this.body.length
    }

    private addEmoji (emoji: {
        id: string
        name: string
        colons: string
        text: string
        emoticons: string[]
        skin: number | null
        native: string
    }) {
        this.body = this.insertString(this.body, emoji.native, this.cursorPosition)
    }

    private addPlaceholder (placeholder: string) {
        this.body = this.insertString(this.body, `%Subscriber:${placeholder}%`, this.cursorPosition)
    }

    private insertString (mainString: string, insString: string, pos?: undefined | number) {
        if (typeof pos === 'undefined') {
            pos = this.body.length
        }
        if (typeof insString === 'undefined') {
            insString = ''
        }
        this.cursorPosition = pos + insString.length
        return mainString.slice(0, pos) + insString + mainString.slice(pos)
    }

    private async createTemplate () {
        const valid = await this.$refs.createWaTemplateForm.validate()
        if (!valid || this.checkPlaceholder(this.body)) return
        this.loading = true
        try {
            await createWaTemplate({
                name: this.name,
                body: this.body,
            })
            this.$emit('template-created')
            this.$root.$vsToast({
                heading: 'Template creato con successo',
                timeout: 3000,
                aspect: VsToastAspectEnum.success,
            })
            this.open = false
        } catch (e) {
            console.log(e)
            this.$root.$vsToast({
                heading: 'Errore durante la creazione del template',
                timeout: 3000,
                aspect: VsToastAspectEnum.alert,
            })
        }
        this.loading = false
    }

    checkConsecutivePlaceholders (placeholders: string[]): boolean {
        // Ordina i placeholder in ordine crescente
        placeholders.sort()

        // Confronta i numeri all'interno dei placeholder con una variabile di confronto
        let expectedNumber = 1
        for (let i = 0; i < placeholders.length; i++) {
            // Estrapola il numero dal placeholder
            const match = placeholders[i].match(/\{\{(\d+)\}\}/)
            if (!match) {
                return false // Contenuto non numerico nel placeholder
            }
            const actualNumber = parseInt(match[1], 10)

            // Controlla se il numero è consecutivo all'atteso
            if (actualNumber !== expectedNumber) {
                return false
            }

            expectedNumber++
        }

        return true
    }

    checkPlaceholder (str: string): boolean {
        const regex = /(\{\{|\}\})/g
        const matches = str.match(regex)
        return !!matches
    }

    checkPlaceholderSpacing (str: string): boolean {
        const regex = /\{\{(.+?)\}\}/g
        const matches = str.match(regex)

        if (!matches) {
            return true // Nessun placeholder trovato
        }

        for (let i = 0; i < matches.length; i++) {
            const match = matches[i]
            const prevCharIndex = str.indexOf(match) - 1
            const nextCharIndex = str.indexOf(match) + match.length

            if (
                (prevCharIndex >= 0 && !this.isSpaceOrNewLine(str[prevCharIndex])) ||
                (nextCharIndex < str.length && !this.isSpaceOrNewLine(str[nextCharIndex]))
            ) {
                return false
            }
        }

        return true
    }

    isSpaceOrNewLine (char: string): boolean {
        return char === ' ' || char === '\n'
    }

    async getCustomFields () {
        try {
            const resp = await getCustomFieldsPlaceholders()
            this.customFields = resp.data.data
        } catch (e) {
            console.log(e)
            this.customFields = []
        }
    }
}
