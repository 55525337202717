















































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'

@Component({
    name: 'VsWhatsappTemplateCard',
    components: {
        VsListingCard,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) template!: any
    @Prop({ default: null, required: true }) integration!: any

    get status () {
        switch (this.template.status) {
            case 'approved': return 'Approvato'
            case 'pending': return 'In approvazione'
            case 'rejected': return 'Rifiutato'
        }
    }

    get badgeStatusAspect () {
        switch (this.template.status) {
            case 'approved': return 'success'
            case 'pending': return 'warning'
            case 'rejected': return 'alert'
            default: return 'default'
        }
    }
}
