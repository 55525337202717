var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('VsModal',{scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(" Crea un template WhatsApp ")]},proxy:true},{key:"actionLeft",fn:function(){return [_c('VsButton',{attrs:{"aspect":"secondary"},on:{"click":_vm.closeModal}},[_vm._v(" Chiudi ")])]},proxy:true},{key:"actionRight",fn:function(){return [_c('VsButton',{attrs:{"loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.createTemplate}},[_vm._v(" Crea ")])]},proxy:true}]),model:{value:(_vm.open),callback:function ($$v) {_vm.open=$$v},expression:"open"}},[_c('ValidationObserver',{ref:"createWaTemplateForm"},[_c('ValidationProvider',{attrs:{"vid":"name","name":"Nome","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsInput',{staticClass:"vs-mb-4",attrs:{"label":"Nome","error":errors.length > 0,"errorMessage":errors[0],"caption":"Dai un nome riconoscibile al tuo template"},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}})]}}])}),_c('ValidationProvider',{attrs:{"vid":"body","name":"Contenuto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('VsTextArea',{ref:"bodyInput",attrs:{"size":"large","label":"Contenuto","error":errors.length > 0 || _vm.checkPlaceholder(_vm.body),"errorMessage":errors[0],"resize":"","minHeight":"180px"},on:{"input":_vm.detectBodyInputCursorPosition},nativeOn:{"click":function($event){return _vm.detectBodyInputCursorPosition.apply(null, arguments)}},model:{value:(_vm.body),callback:function ($$v) {_vm.body=$$v},expression:"body"}})]}}])})],1),(_vm.checkPlaceholder(_vm.body))?_c('VsCaption',{staticClass:"vs-text-alert-900"},[_vm._v(" Non è ammesso del testo con doppie graffe {{}} ")]):_vm._e(),_c('div',{staticClass:"vs-items-center vs-gap-4 vs-flex vs-mt-4 vs-mb-8"},[_c('div',{staticClass:"vs-flex-auto"},[_c('VsSelect',{attrs:{"value":"","filterable":"","labelHidden":true,"placeholder":"Seleziona un elemento da aggiungere nel contenuto","boundary":"viewport","options":_vm.customFields.map(function (cf) {
                    return  {
                        value: cf,
                        label: cf,
                    }
                })},on:{"input":_vm.addPlaceholder}})],1),_c('div',{staticClass:"vs-flex-none"},[_c('VsPopover',{attrs:{"placement":"bottom-start","boundary":"window"},scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('VsButton',{directives:[{name:"tippy",rawName:"v-tippy"}],staticClass:"vs-pr-3 vs-pl-3",attrs:{"size":"large","title":"Aggiungi emoji","aspect":"secondary"}},[_vm._v(" 😀 ")])]},proxy:true}])},[[_c('Picker',{attrs:{"set":"google","i18n":_vm.$t('campaigns.editCampaign.sections.subject.emoji'),"recent":[],"multiCustom":[],"title":'Seleziona un emoji',"color":"#D03449","native":true,"emoji":"point_up"},on:{"select":_vm.addEmoji}})]],2)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }