









































import { Component, Prop, Vue } from 'vue-property-decorator'
import VsListingCard from '@/components/VsListingCard/Index.vue'
import VsDropdownButton from '@/components/VsDropdownButton/Index.vue'
import { requestIntegration } from '@/api/consoleApi/contents/whatsapp'
import { VsToastAspectEnum } from '@advision/vision/src/components/VsToast/types'

@Component({
    name: 'VsWhatsappIntegration',
    components: {
        VsListingCard,
        VsDropdownButton,
    },
})
export default class extends Vue {
    @Prop({ default: null, required: true }) integration!: any
    loading = false
    phone = ''

    async requestIntegration () {
        this.loading = true
        try {
            const resp = await requestIntegration({
                phone: this.phone,
            })
            this.$root.$vsToast({
                heading: 'Integrazione richiesta con successo',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            this.$emit('integration-created', resp.data.data)
        } catch (e) {
            this.$root.$vsToast({
                heading: 'Errore durante la richiesta',
                aspect: VsToastAspectEnum.success,
                timeout: 3000,
            })
            console.log(e)
            this.integration = null
        }
        this.loading = false
    }
}
